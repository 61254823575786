import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  TemplateRef,
} from '@angular/core';
import { ButtonDirective } from '@fc-shared/ui/buttons/base/button.directive';
import { MatRipple } from '@angular/material/core';
import { CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';

@Component({
  selector: 'fc-button-menu',
  template: `
    <div class="menu-button">
      <button
        matRipple
        class="base-button"
        (click)="onButtonClick($event)"
        [class.disabled]="disabled()"
      >
        <ng-content />
      </button>
      <div
        matRipple
        cdkMenuItem
        [cdkMenuTriggerFor]="menuTemplate()"
        class="menu-button-item"
      >
        <fc-icon
          color="white"
          [size]="20"
          icon="icon-arrow_drop_down"
        ></fc-icon>
      </div>
    </div>
  `,
  styleUrls: ['./base/menu-button.scss', './base/button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatRipple, CdkMenuItem, CdkMenuTrigger, IconComponent],
})
export class MenuButtonComponent extends ButtonDirective {
  menuTemplate = input<TemplateRef<void> | null>(null);
  menuClicked = output();

  onButtonClick(evt: Event): void {
    if (this.disabled()) return;
    this.onClick.emit(evt);
  }
}
